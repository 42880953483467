<div appBsModal #smsVerificationModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #smsVerificationModalForm="ngForm" (ngSubmit)="save()">

                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{"VerifyYourCode" | localize}}</span>
                    </h5>
                    <button type="button" class="close" [attr.aria-label]="l('Close')" (click)="close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div id="inputDiv" class="form-group">
                        <label for="YourCode">{{"YourCode" | localize}} *</label>
                        <input id="YourCode" #codeInput="ngModel" class="form-control" autoFocus type="text" name="Code" [(ngModel)]="verifyCodeInput.code" required maxlength="6" minlength="6">
                        <validation-messages [formCtrl]="codeInput"></validation-messages>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary" [disabled]="!smsVerificationModalForm.form.valid || saving"><i class="fa fa-save"></i> <span>{{"Verify" | localize}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
