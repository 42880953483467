<div class="card card-custom h-100">
    <div class="card-header align-items-center border-0">
        <div class="card-title">
            <span class="card-icon">
                <i class="la la-pie-chart text-primary"></i>
            </span>
            <h3 class="card-label text-success">
                {{"IncomeStatistics" | localize}}
                <small class="text-muted">{{selectedDateRange[0] | momentFormat: 'L'}}
                    - {{selectedDateRange[1] | momentFormat: 'L'}}</small>
            </h3>
        </div>
        <div class="card-toolbar">
            <div class="btn-group btn-group-devided" data-toggle="buttons">
                <label class="btn m-btn--pill btn-secondary"
                       [ngClass]="{'active': selectedIncomeStatisticsDateInterval ==  appIncomeStatisticsDateInterval.Daily}"
                       (click)="incomeStatisticsDateIntervalChange(appIncomeStatisticsDateInterval.Daily)">
                    <input type="radio" name="IncomeStatisticsDatePeriod" class="toggle"
                           [value]="appIncomeStatisticsDateInterval.Daily">
                    {{"Daily" | localize}}
                </label>
                <label class="btn m-btn--pill btn-secondary"
                       [ngClass]="{'active': selectedIncomeStatisticsDateInterval ==  appIncomeStatisticsDateInterval.Weekly}"
                       (click)="incomeStatisticsDateIntervalChange(appIncomeStatisticsDateInterval.Weekly)">
                    <input type="radio" name="IncomeStatisticsDatePeriod" class="toggle"
                           [value]="appIncomeStatisticsDateInterval.Weekly">
                    {{"Weekly" | localize}}
                </label>
                <label class="btn m-btn--pill btn-secondary"
                       [ngClass]="{'active': selectedIncomeStatisticsDateInterval ==  appIncomeStatisticsDateInterval.Monthly}"
                       (click)="incomeStatisticsDateIntervalChange(appIncomeStatisticsDateInterval.Monthly)">
                    <input type="radio" name="IncomeStatisticsDatePeriod" class="toggle"
                           [value]="appIncomeStatisticsDateInterval.Monthly">
                    {{"Monthly" | localize}}
                </label>
            </div>
        </div>
    </div>
    <div class="card-body" [perfectScrollbar]>
        <ngx-charts-area-chart *ngIf="incomeStatisticsHasData" [busyIf]="loadingIncomeStatistics"
                               [results]="incomeStatisticsData" [showXAxisLabel]="true" showYAxisLabel="true" [xAxis]="true" [yAxis]="true"
                               [showGridLines]="true">
            <ng-template #tooltipTemplate let-model="model">
                <span>{{model.name}}</span>
                <br />
                <span>{{'IncomeWithAmount' | localize}} {{model.value + ' ' + appSession.application.currencySign}}</span>
            </ng-template>
        </ngx-charts-area-chart>
        <div class="note note-info text-center chart" [hidden]="incomeStatisticsHasData">
            <small class="text-muted">{{"NoData" | localize}}</small>
        </div>
    </div>
</div>
